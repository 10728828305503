import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getAlgo } from './simulationSlice';
// import apiRequest from '../../utility/apiRequest';
import { getItem, removeItem, setItem } from '../../utility/localStorageControl';
import tokenExpiryCheck from '../../utility/tokenExpiryCheck';
import axios from '../../config/dataService/axios';


export const initialState = {
  isLoggedIn: false,
  access_token: null,
  refresh_token: null,
  loading: false,
  userData: null,
  error: null
};

export const logout = createAsyncThunk('auth/logout', async(data, { rejectWithValue }) => {

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/logout`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }

  try {

    await axios.request(apiConfig);

    removeItem('isLoggedIn');
    removeItem('access_token');
    removeItem('refresh_token');

    return false;

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAccessByRefresh = createAsyncThunk('auth/getAccessByRefresh', async(data, { dispatch, rejectWithValue }) => {

  const refresh_token = getItem('refresh_token');

  const istokenExpired = tokenExpiryCheck(refresh_token);

  if(istokenExpired){
    dispatch(logout());
    return rejectWithValue('Session Expired. Please Login Again !');
  }

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/refresh`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${refresh_token}`
    }
  }

  try {

    const result = await axios.request(apiConfig);
    // const result = await apiRequest(apiConfig);
    setItem('access_token', result.data.access_token);
    return {'status': true, 'access_token': result.data.access_token};

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUser = createAsyncThunk('auth/getUser', async(data,{ dispatch, rejectWithValue}) => {

  const access_token = getItem('access_token');

  const istokenExpired = tokenExpiryCheck(access_token);
  if(istokenExpired){
    await dispatch(getAccessByRefresh());
  }


  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/user_profile`,
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${getItem('access_token')}`,
      'Content-Type': 'application/json',
    }
  }

  try {

    const result = await axios.request(apiConfig);

    // const result = await apiRequest(apiConfig);
    return result.data;

  } catch (error) {
    return rejectWithValue(error);
  }
})

export const login = createAsyncThunk('auth/login', async (userdata, { rejectWithValue }) => {

  const apiConfig = {
    url: `${process.env.REACT_APP_API_URL}/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userdata)
  }

  try {

    const result = await axios.request(apiConfig);

    if (result.data.message) {
      return rejectWithValue(result.data.message);
    } 

    setItem('isLoggedIn', true);
    setItem('access_token', result.data.access_token);
    setItem('refresh_token', result.data.refresh_token);

    return {'status': true, 'access_token': result.data.access_token};
    
  } catch (err) {
    return rejectWithValue(err);
  }

});

// export const loginWithUserData = (userdata) => async(dispatch) => {
//   await dispatch(login(userdata));
//   await dispatch(getUser());
// }




export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateError: (state, action) => {
      state.error = action.payload;
    },
    setUserData: (state, action) => {
      state.useData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.login = action.payload.status;
        state.isLoggedIn = action.payload.status;
        state.access_token = action.payload.access_token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state,action) => {
        state.loading = false;
        state.isLoggedIn = action.payload;
        state.access_token = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state,action) => {
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getAccessByRefresh.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccessByRefresh.fulfilled, (state,action) => {
        state.loading = false;
        state.isLoggedIn = action.payload.status;
        state.access_token = action.payload.access_token;
      })
      .addCase(getAccessByRefresh.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { updateError, setUserData } = authSlice.actions;

export default authSlice.reducer;
