import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessByRefresh } from './authSlice';
import tokenExpiryCheck from '../../utility/tokenExpiryCheck';
import axios from '../../config/dataService/axios';
import { getItem } from '../../utility/localStorageControl';
import candle_dummy_data  from '../../demoData/candlestick-data.json';
import PLData from '../../demoData/pl-chart.json';
// import SingleSim from '../../demoData/dummy-single-sim-data.json';

export const initialState = {
    loading: false,
    chipData: [''],
    totalChips: [],
    totalCandleData: null,

    plSwitchData: [''],
    totalPlData: null,
    totalUniquePlTickerData: [],

    tempSingleSimulationData: [],
    error: null
};

export const getTotalUniqueTickers = createAsyncThunk('chartSlice/getTotalUniqueTickers', async(a, { rejectWithValue }) => {
    
    // const access_token = getItem('access_token');

    // const apiConfig = {
    //     url: `${process.env.REACT_APP_API_URL}/`, // Endpoint to be added
    //     method: 'GET',
    //     headers: {
    //       'Authorization': `Bearer ${access_token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   };

    // const istokenExpired = tokenExpiryCheck(access_token);
    // if(istokenExpired){
    //   await dispatch(getAccessByRefresh()); // dispatch to be added
    // }
    const totalChipsData = [];
    
    try {

        // const result = await axios(apiConfig);
        
        // result.data  replaces candle_dummy_data
        candle_dummy_data.forEach(data => {
            if(!totalChipsData.includes(data.trading_symbol)){
                totalChipsData.push(data.trading_symbol);
            }
        });

        return {"all_data": candle_dummy_data, "total_unique_tickers": totalChipsData};

    } catch (error) {
        rejectWithValue(error);
    }
});

export const getSimulationsPL = createAsyncThunk('chartSlice/getSimulationsPL', async(value, { rejectWithValue }) => {
    
    // const access_token = getItem('access_token');

    // const apiConfig = {
    //     url: `${process.env.REACT_APP_API_URL}/`, // Endpoint to be added
    //     method: 'GET',
    //     headers: {
    //       'Authorization': `Bearer ${access_token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   };

    // const istokenExpired = tokenExpiryCheck(access_token);
    // if(istokenExpired){
    //   await dispatch(getAccessByRefresh()); // dispatch to be added
    // }
    const totalChipsData = [];
    
    try {

        // const result = await axios(apiConfig);
        
        // result.data  replaces candle_dummy_data
        PLData[value].forEach(data => {
            if(!totalChipsData.includes(data.ticker)){
                totalChipsData.push(data.ticker);
            }
        });

        return {"all_pl_data": PLData[value], "total_unique_tickers": totalChipsData};

    } catch (error) {
        rejectWithValue(error);
    }
});

export const getSingleSimulationData = createAsyncThunk('simulation/getSingleSimulationData', async (data,{ dispatch, rejectWithValue}) => {

    // const state = getState();
    const access_token = getItem('access_token');
  
    const istokenExpired = tokenExpiryCheck(access_token);
    if(istokenExpired){
      await dispatch(getAccessByRefresh());
    }


    let bodyData = {};
    if(data.optimisation_algo_id === null){
        bodyData = {
            "action": data.action,
            "algo_id": data.algo_id,
            "amount": data.amount,
            "base": data.base,
            "buy_at": data.buy_at,
            "duration": data.duration,
            "end_time": data.end_time,
            "follower": data.follower,
            "from_date": data.from_date,
            "instrument_id": data.instrument_id,
            "sell_at": data.sell_at,
            "start_time": data.start_time,
            "stoploss": data.stoploss,
            "to_date": data.to_date
          }
    }else{
        bodyData = {
            "action": data.action,
            "algo_id": data.algo_id,
            "amount": data.amount,
            "base": data.base,
            "buy_at": data.buy_at,
            "duration": data.duration,
            "end_time": data.end_time,
            "follower": data.follower,
            "from_date": data.from_date,
            "instrument_id": data.instrument_id,
            "optimisation_algo_id": data.optimisation_algo_id,
            "sell_at": data.sell_at,
            "start_time": data.start_time,
            "stoploss": data.stoploss,
            "to_date": data.to_date
          }
    }

    // return SingleSim[data.id];

    const apiConfig = {
      url: `${process.env.REACT_APP_API_URL}/simulation/${data.id}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: bodyData,
    };
    
    try {
        const result = await axios(apiConfig);
  
        if (result.data.message) {
          return rejectWithValue(`Simulation Analysis: ${result.data.message}`);
        } 
  
        // simulationBarChart to be removed after api data configured

        return result.data;
    } catch (err) {
      return rejectWithValue('Failed to get analysis data');
    }
  });


export const chartSlice = createSlice({
    name: 'chartSlice',
    initialState,
    reducers: {
        setChipData: (state, action) => {
            state.chipData = action.payload;
        },
        setTotalChips: (state, action) => {
            state.totalChips = action.payload;
        },
        setPlSwitchData: (state, action) => {
            state.plSwitchData = action.payload;
        } ,
        setTotalUniqueTickers: (state, action) => {
            state.totalUniquePlTickerData = action.payload;
        },
        setTempSingleSimulationData: (state,action) => {
            state.tempSingleSimulationData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(getTotalUniqueTickers.pending, (state) => {
            state.loading = true;
          })
          .addCase(getTotalUniqueTickers.fulfilled, (state, action) => {
            state.loading = false;
            state.totalChips = action.payload.total_unique_tickers;
            state.totalCandleData = action.payload.all_data;
          })
          .addCase(getTotalUniqueTickers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(getSimulationsPL.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSimulationsPL.fulfilled, (state, action) => {
            state.loading = false;
            state.totalUniquePlTickerData = action.payload.total_unique_tickers;
            state.totalPlData = action.payload.all_pl_data;
          })
          .addCase(getSimulationsPL.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(getSingleSimulationData.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSingleSimulationData.fulfilled, (state, action) => {
            state.loading = false;
            state.tempSingleSimulationData = [...state.tempSingleSimulationData,action.payload];
          })
          .addCase(getSingleSimulationData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
    }
});


export const { setChipData, setTotalChips, setPlSwitchData, setTotalUniqueTickers, setTempSingleSimulationData } = chartSlice.actions;

export default chartSlice.reducer;
