import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../custom-layout/withAdminLayout';


const Dashboard = lazy(() => import('./dashboard'));
const Simulations = lazy(() => import('./simulations'));
const Candlestick = lazy(() => import('./candlestick'));
const Plchart = lazy(() => import('./plchart'));
const NotFound = lazy(() => import('../../custom-container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>

        <Route index path="dashboard/*" element={<Dashboard />} />
        <Route path='simulations/*' element={<Simulations />}/>
        <Route path='candlestick' element={<Candlestick />}/>
        <Route path='plchart' element={<Plchart />}/>
        <Route path="*" element={<NotFound />} />

      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
